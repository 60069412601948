var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.app.componentLoading || _vm.isLoading),expression:"$store.state.app.componentLoading || isLoading"}],staticClass:"overflow-hidden"},[_c('b-skeleton-table',{attrs:{"rows":5,"columns":4,"table-props":{ bordered: true, striped: true }}})],1),_c('vue-good-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$store.state.app.componentLoading || _vm.isLoading),expression:"!$store.state.app.componentLoading || isLoading"}],ref:"data-table",attrs:{"mode":"remote","rows":_vm.rows,"columns":_vm.tableColums,"style-class":_vm.styleClass,"sort-options":_vm.sortOptions,"max-height":(_vm.maxHeight + "px"),"group-options":_vm.groupOptions},on:{"on-sort-change":function($event){return _vm.$emit('onSortChange', $event)}},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(
          props.column.label === 'checkbox' &&
            _vm.selectOptions.selectOnlyOne &&
            _vm.selectOptions.selectOnlyOne === true
        )?_c('span'):(props.column.label === 'checkbox')?_c('b-form-checkbox',{attrs:{"indeterminate":_vm.indeterminate,"checked":_vm.selectedAll},on:{"change":_vm.checkedAll}}):_vm._t("table-column",null,{"row":props.row,"column":props.column,"index":props.index})]}},{key:"table-row",fn:function(props){return [(props.column.field === 'checkbox')?_c('b-form-checkbox',{attrs:{"checked":props.row.isSelected,"disabled":props.row.isDisabled && props.row.isDisabled == true},on:{"change":function($event){return _vm.selectedChange($event, props.row)}}}):_vm._t("table-row",null,{"row":props.row,"column":props.column,"index":props.index})]}},{key:"table-header-row",fn:function(props){return [(
          _vm.groupOptions.allowEmptySelect &&
            _vm.groupOptions.allowEmptySelect === true &&
            props.column &&
            props.column.field === 'checkbox'
        )?_c('b-form-checkbox',{attrs:{"checked":props.row.isSelected},on:{"change":function($event){return _vm.selectedChange($event, props.row)}}}):_vm._t("table-header-row",null,{"row":props.row,"column":props.column,"index":props.index})]}}])},[_c('template',{slot:"emptystate"},[_c('div',{staticClass:"empty-table d-block"},[_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-center"},[_c('b-img',{staticClass:"img-empty-table",attrs:{"fluid":"","src":_vm.emptyImg,"alt":"empty table"}})],1)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }