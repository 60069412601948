<template>
  <b-modal
    :id="id"
    size="md"
    title="Thêm vị trí"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateLight"
    @hidden="resetState"
    @show="fetchDataCombobox"
  >
    <validation-observer ref="rule">
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group
            label-for="code"
          >
            <label for="code">Vị trí trắc quan <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="position"
              rules="required"
              :custom-messages="{required: `Vị trí quan trắc là bắt buộc`}"
            >
              <b-form-input
                v-model="dataInput.position"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label-for="code"
          >
            <label for="code">Loại quy chuẩn <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="lightingLevelId"
              rules="required"
              :custom-messages="{required: `Loại quy chuẩn là bắt buộc`}"
            >
              <LightSelect
                v-model="dataInput.lightingLevelId"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label-for="code"
          >
            <label for="code">Số mẫu đạt <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="reachedOk"
              rules="required"
              :custom-messages="{required: `Số mẫu đạt là bắt buộc`}"
            >
              <b-form-input
                v-model="dataInput.reachedOk"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label-for="code"
          >
            <label for="code">Số mẫu không đạt <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="reachedNot"
              rules="required"
              :custom-messages="{required: `Số mẫu không đạt là bắt buộc`}"
            >
              <b-form-input
                v-model="dataInput.reachedNot"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import LightSelect from '@/views/occupational-safety-health/menu-observation-monitor/pages/component/LightSelect.vue'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormInput,
    DateTimePicker,
    ValidationProvider,
    ValidationObserver,
    BForm,
    LightSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataLightId: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataInput: {
        lightingLevelId: '',
        position: '',
        reachedOk: 0,
        reachedNot: 0,
      },
      dataCombobox: [],
    }
  },
  watch: {
    dataLightId(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          lightingLevelId: this.dataLightId.lightingLevelId,
          position: this.dataLightId.position,
          reachedOk: this.dataLightId.reachedOk,
          reachedNot: this.dataLightId.reachedNot,
        }
      }
    },
  },
  methods: {
    // combobox Loại quy chuẩn
    async fetchDataCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_MONITOR_LIGHT)
      this.dataCombobox = data?.data
    },

    // action tạo yếu tố thời tiết
    handleCreateLight(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateLight', this.dataInput)
        }
      })
    },

    resetState() {
      this.dataInput = {
        lightingLevelId: '',
        position: '',
        reachedOk: 0,
        reachedNot: 0,
      }
    },
  },
}
</script>
