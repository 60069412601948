<!-- =========================================================================================
  File Name: 'DatePicker.vue'
  Description: 'Date picker'
  ----------------------------------------------------------------------------------------
  Author: Thai Nguyen Tan
  Created Date: "2021-04-15"
  Modified Date: "2021-04-15"
========================================================================================== -->
<template>
  <div>
    <b-input-group
      :disabled="disabled"
      class="date-picker-format"
    >
      <b-form-input
        v-model="dateFormat"
        :disabled="disabledInput"
        type="text"
        placeholder="dd/mm/yyyy"
        autocomplete="off"
        show-decade-nav
        @change="inputChange"
      />
      <b-input-group-append :disabled="disabled">
        <b-button
          v-if="showClose"
          class="color-button"
          variant="outline-secondary"
          @click="resetInput"
        >
          <feather-icon
            icon="XIcon"
            size="15"
          />
        </b-button>

        <b-form-datepicker
          v-model="date"
          :disabled="disabled"
          class="custome-datepicker"
          show-decade-nav
          button-variant="outline-primary"
          size="sm"
          button-only
          right
          aria-controls="example-input"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          :locale="lang==='en'?'en':'vi'"
          @context="onContext"
        />
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import {
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledInput: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // date: null,
      // dateFormat: '',
      // isFirst: true,
      // lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
      date: this.value !== null ? this.value : '',
      dateFormat: '',
      isFirst: true,
      lang: this.$language(),
    }
  },

  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.date = newValue !== null ? newValue : ''
      }
    },
  },
  methods: {
    // thay đổi nội dung nhập
    inputChange(value) {
      if (value.length === null || value.length === 0) {
        this.date = ''
      } else {
        let strs = value.split('/')
        if (strs.length === 3) {
          const dateData = `${strs[2]}-${strs[1]}-${strs[0]}`
          const mydate = new Date(dateData)
          if (Object.prototype.toString.call(mydate) === '[object Date]') {
            // eslint-disable-next-line no-restricted-globals
            if (!isNaN(mydate.getTime())) {
              this.date = `${mydate.getFullYear()}-${
                mydate.getMonth() + 1
              }-${mydate.getDate()}`
              return
            }
          }
        }
        strs = this.date.split('-')
        if (strs.length === 3) {
          this.dateFormat = `${strs[2]}/${strs[1]}/${strs[0]}`
        }
      }
    },
    // lựa chọn ngày thay đổi
    onContext(ctx) {
      if (ctx.selectedYMD.length > 0) {
        this.dateFormat = ctx.selectedFormatted
      } else {
        this.dateFormat = null
      }
      // emit nếu không phải lần đầu load
      if (!this.isFirst) { this.$emit('input', ctx.selectedYMD) }
      this.isFirst = false
    },

    // bỏ chọn ngày tháng trong ô input
    resetInput() {
      this.date = ''
      this.inputChange(this.date)
    },
  },
}
</script>
<style lang ="scss" >
.custome-datepicker {
  .btn-outline-primary {
    border: 1px solid #d8d6de !important;
  }
}
.date-picker-format {
  &:focus-within .form-control {
    border-color: #d8d6de !important;
  }
}

.dropdown-menu {
  z-index: 9999 !important;
}

.color-button {
  border: 1px solid #d8d6de !important;
  border-right: unset !important;
  padding: 0 15px 0 15px !important;
}
</style>
