<template>
  <div>
    <!--phần header-->
    <button-all-header
      :contentBtnAdd="'Thêm yếu tố'"
      :arrayExcel="[]"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDelete="deleteItems"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />
    <!--Phần Bảng -->
    <vue-good-table
      ref="user-table"
      :columns="columns"
      :rows="dataTable || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'code'">
          <b-badge variant="light-primary">
            {{ props.row.code }}
          </b-badge>
        </span>
        <!-- Chức năng -->
        <span v-else-if="props.column.field == 'action'">
          <feather-icon
            icon="EditIcon"
            size="18"
            class="text-body mr-1"
            @click="showModalEdit(props.row.id)"
          />
          <feather-icon
            icon="Trash2Icon"
            size="18"
            class="text-body"
            @click="deleteItem(props.row.id)"
          />
        </span>
      </template>
    </vue-good-table>
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="handlePageClick"
    />

    <!-- modal xóa-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <edit-job
      :id="modalCreateJob"
      :type="modalType"
      :dataJobId="dataJobId"
      @handleCreateJob="handleCreateJob"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import EditJob from './modal-create/EditJob.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    EditJob,
  },
  data() {
    return {
      totalRecord: 0,
      dataTable: [],
      columns: [
        {
          label: 'VỊ TRÍ VIỆC LÀM',
          field: 'position',
          sortable: false,
        },
        {
          label: 'MÔ TẢ NỘI DUNG CÔNG VIỆC',
          field: 'content',
          sortable: false,
        },
        {
          label: 'SỐ LƯỢNG NGƯỜI TIẾP XÚC',
          field: 'numberOfContacts',
          sortable: false,
        },
        {
          label: 'YẾU TỐ TIẾP XÚC',
          field: 'contactFactor',
          sortable: false,
        },
        {
          label: 'BỆNH NGHỀ NGHIỆP CÓ THỂ PHÁT SINH',
          field: 'occupationalDisease',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          sortable: false,
        },
      ],
      urlQuery: {
        observationMonitorId: this.$route.params.id,
        search: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      modalCreateJob: 'comfirm-create-job',
      deleteIds: [],
      showBtnMultiDelete: false,
      jobId: null,
      modalType: null,
      dataJobId: {},
    }
  },
  created() {
    this.fetchDataListJob(this.urlQuery)
  },
  methods: {
    // Lấy danh sách quan trắc người lao động
    async fetchDataListJob(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_JOB, {
        params: urlQuery,
      })
      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Mở modal tạo và chỉnh sửa kiểm định viên
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalCreateJob)
    },

    // Mở modal tạo và chỉnh sửa kiểm định viên
    async showModalEdit(id) {
      this.modalType = 'edit'
      this.jobId = id
      this.$bvModal.show(this.modalCreateJob)
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_JOB}${this.jobId}`)
      this.dataJobId = data
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchDataListJob(this.urlQuery)
    },

    // tìm kiếm lộ trình đào tạo
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        this.fetchDataListJob(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        this.fetchDataListJob(this.urlQuery)
      }
    },
    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.delete(ConstantsApi.DELETE_JOB, {
        params: {
          model: JSON.stringify(this.deleteIds),
          observationMonitorId: this.$route.params.id,
        },
      }).then(res => {
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Xóa thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
        this.$bvModal.hide(this.modalCreateJob)
        this.fetchDataListJob(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Action tạo mới và chỉnh sửa cơ cấu tổ chức
    async handleCreateJob(val) {
      const payload = {
        ...val,
        observationMonitorId: this.$route.params.id,
        numberOfContacts: parseInt(val.numberOfContacts, 10),
      }
      if (this.modalType === 'edit') {
        const data = {
          ...payload,
          id: this.jobId,
        }
        this.$showAllPageLoading()
        await axiosApiInstance.put(ConstantsApi.EDIT_JOB, data).then(res => {
          if (res.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Cập nhật thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.fetchDataListJob(this.urlQuery)
            this.$bvModal.hide(this.modalCreateJob)
          } else {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        })
      }
      if (this.modalType === 'add') {
        this.$showAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.CREATE_JOB, payload).then(res => {
          if (res.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Tạo thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalCreateJob)
            this.fetchDataListJob(this.urlQuery)
          } else {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
</style>
